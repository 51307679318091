// import localforage from "localforage";

// import { matchSorter } from "match-sorter";
// import sortBy from "sort-by";
import axios from 'axios';
import { REACT_APP_API_TRUEUP, REACT_APP_X_API_KEY } from '../globalvariable.js';

export async function getSMSs() {
  //   await fakeNetwork(`getContacts:${query}`);
  let SMSs = sessionStorage.getItem("SMSs");
  if (!SMSs) SMSs = [];
  //   if (query) {
  //     SMSs = matchSorter(SMSs, query, { keys: ["first", "last"] });
  //   }
  //   return SMSs.sort(sortBy("last", "createdAt"));
  return SMSs;
}

export async function createSMS(SMS) {
  //   await fakeNetwork();
  //   let id = Math.random().toString(36).substring(2, 9);
  // let SMS = { from, to, body };
  let SMSs = await getSMSs();

  SMSs.push(SMS);
  set(SMSs);
  return SMS;

  //   await fakeNetwork();
  //   let id = Math.random().toString(36).substring(2, 9);
  //   let contact = { id, createdAt: Date.now() };
  //   let contacts = await getContacts();
  //   contacts.unshift(contact);
  //   await set(contacts);
  //   return contact;
}

// export async function getContact(id) {
//   await fakeNetwork(`contact:${id}`);
//   let contacts = await localforage.getItem("contacts");
//   let contact = contacts.find(contact => contact.id === id);
//   return contact ?? null;
// }

// export async function updateContact(id, updates) {
//   await fakeNetwork();
//   let contacts = await localforage.getItem("contacts");
//   let contact = contacts.find(contact => contact.id === id);
//   if (!contact) throw new Error("No contact found for", id);
//   Object.assign(contact, updates);
//   await set(contacts);
//   return contact;
// }

// export async function deleteContact(id) {
//   let contacts = await localforage.getItem("contacts");
//   let index = contacts.findIndex(contact => contact.id === id);
//   if (index > -1) {
//     contacts.splice(index, 1);
//     await set(contacts);
//     return true;
//   }
//   return false;
// }

function set(SMSs) {
  return sessionStorage.setItem("SMSs", SMSs);
}

// fake a cache so we don't slow down stuff we've already seen
// let fakeCache = {};

// async function fakeNetwork(key) {
//   if (!key) {
//     fakeCache = {};
//   }

//   if (fakeCache[key]) {
//     return;
//   }

//   fakeCache[key] = true;
//   return new Promise(res => {
//     setTimeout(res, Math.random() * 800);
//   });
// }

export async function getCustomers(email) {
  if (!email)
    return [];

  const data = {
    email: email,
  };

  axios.post(`${REACT_APP_API_TRUEUP}customers`, data, {
    headers: {
      'accept': 'application/json',
      'x-api-key': REACT_APP_X_API_KEY,
      // 'Authorization': `Bearer ${googleProfile.id_token}`,
      'Content-Type': 'application/json'
    }
  })
    .then(response => {
      console.log(`SMSs.js::getCustomers: ${response.data}`);
      return response.data;
    })
    .catch(error => {
      console.error('There was an error!', error);
    });

}