import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import { ButtonGroup, Image } from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useEffect, useState } from 'react';
import axios from 'axios';

import { useProfileContext } from "../home/Layout";

// import { REACT_APP_API_TRUEUP, REACT_APP_X_API_KEY } from '../constants';

function Mechant() {
    const { googleProfile } = useProfileContext();

    const [isMerchantEdit, setIsMerchantEdit] = useState(false);
    const [isPhoneEdit, setIsPhoneEdit] = useState(false);
    const [isContactEdit, setIsContactEdit] = useState(false);

    // useEffect(() => {
    //     // Call the Trueup backend API to retrieve the merchant's information
    //     // Use the merchant's information to populate the form fields
    //     const data = {
    //         email: googleProfile.email,
    //     };

    //     axios.post(`${REACT_APP_API_TRUEUP}merchant`, data, {
    //     // axios.post(process.env.REACT_APP_API_TRUEUP + "customers", data, {
    //         headers: {
    //             'accept': 'application/json',
    //             'x-api-key': REACT_APP_X_API_KEY,
    //             'Content-Type': 'application/json'
    //         }
    //     })
    //         .then(response => {

    //         })
    //         .catch(error => {
    //             console.error('There was an error!', error);
    //         });

    // }, []);

    const handleMerchantEditButtonClick = () => {
        if (isMerchantEdit) {
            setIsMerchantEdit(false);
        } else {
            setIsMerchantEdit(true);
        }
    }

    const handleContactEditClick = () => {
        if (isContactEdit) {
            setIsContactEdit(false);
        } else {
            setIsContactEdit(true);
        }
    }

    const handlePhoneEditClick = () => {
        if (isPhoneEdit) {
            setIsPhoneEdit(false);
        } else {
            setIsPhoneEdit(true);
        }
    }

    return (
        <main>
        <Container className="px-0" style={{ paddingTop: '100px', paddingBottom: '50px' }}>
            {googleProfile ? (
            <>
                <Row>
                    <h2>Merchant account</h2>
                </Row>

                <Row>
                    <Row><h6>Merchant Avarta</h6></Row>
                    <Row>
                        <Col md={4}>
                            <Image src="/trueup.jpg" width={100} rounded />
                        </Col>
                        {/* <Col md={1}>
                        <Form>
                            <Button variant=''>Remove</Button>

                        </Form>
                    </Col>
                    <Col md={1}>
                        <Form>
                            <Button variant=''>Change</Button>

                        </Form>
                    </Col> */}
                    </Row>
                </Row>
                <Row>
                    <Row>
                        <h6>Merchant Name</h6>
                    </Row>
                    <Row className="mb-3">
                        <Col md="5">
                            <Form.Group as={Col} controlId="formMerchant">
                                {/* <Form.Text>{"TechArtisan LLC"}</Form.Text> */}
                                {isMerchantEdit ?
                                    (<Form.Control placeholder="Enter your business's name" />)
                                    :
                                    (<Form.Text>{"TechArtisan LLC"}</Form.Text>)

                                }
                            </Form.Group>
                        </Col>
                        <Col>
                            <Button variant='' onClick={handleMerchantEditButtonClick}>
                                {isMerchantEdit ? "Save" : "Edit"}
                            </Button>
                        </Col>


                    </Row>
                </Row>
                <Row>
                    <Row>
                        <h6>Phone</h6>
                    </Row>
                    <Row className="mb-3">
                        <Col md="5">
                            <Form.Group as={Col} controlId="formMerchant">

                                {
                                    isPhoneEdit
                                        ?
                                        (<Row className="mb-3">
                                            <Col md="5">
                                                <Form.Select aria-label="Select an available phone number">
                                                    {/* <option>Open this select menu</option> */}
                                                    <option value="1">+18880506060</option>
                                                    <option value="2">+14080506060(Local)</option>
                                                    <option value="3">+18000506060</option>
                                                </Form.Select>
                                            </Col>
                                            {/* <Col>
                                        <Button variant=''>
                                            Choose
                                        </Button>
                                    </Col> */}
                                        </Row>)
                                        :
                                        (<Form.Text>{"+16552345234"}</Form.Text>)
                                }

                            </Form.Group>
                        </Col>
                        <Col>
                            <Button variant='' onClick={handlePhoneEditClick}>
                                {isPhoneEdit ? "Save" : "Edit"}
                            </Button>
                        </Col>
                    </Row>
                </Row>
                <Row>
                    <Row>
                        <h6>Contact</h6>
                    </Row>
                    <Row className="mb-3">
                        <Col md="5">
                            <Form.Group as={Col} controlId="formMerchant">
                                {
                                    isContactEdit
                                        ?
                                        <Row className="mb-3">
                                            <Col>
                                                <Form.Group as={Col} controlId="formMerchant">
                                                    <Form.Control placeholder='First Name' />
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group as={Col} controlId="formMerchant">
                                                    <Form.Control placeholder='Last Name' />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        :
                                        (<Form.Text>{"Summer Fang"}</Form.Text>)
                                }
                            </Form.Group>
                        </Col>
                        <Col>
                            <Button variant='' onClick={handleContactEditClick}>
                                {isContactEdit ? 'Save' : 'Edit'}
                            </Button>
                        </Col>
                    </Row>
                </Row>
                <Row>
                    <Row>
                        <h6>Email</h6>
                    </Row>
                    <Row className="mb-3">
                        <Col md="5">
                            <Form.Group as={Col} controlId="formMerchant">
                                <Form.Text>{"summerfang@gmail.com"}</Form.Text>

                            </Form.Group>
                        </Col>
                        {/* <Col>
                        <Button variant=''>
                            Change
                        </Button>
                    </Col> */}
                    </Row>
                </Row>
                <Row>
                    <Row>
                        <Col md={5}>
                            <h6>Connected social account</h6>
                            <p>Services that you use to sign into Trueup</p>
                        </Col>
                        {/* <Col>
                        <Button variant=''>
                            Add
                        </Button>
                    </Col> */}

                    </Row>
                    <Row className="mb-3">
                        <Row>
                            <Col md={1}>
                                <img src="/Google__G__logo.svg.png" width={50} />
                            </Col>
                            <Col md="4">
                                <Row>
                                    <h6>Google</h6>
                                </Row>
                                <Row>
                                    <p>Summer Fang</p>
                                </Row>
                            </Col>
                            {/* <Col>
                            <Button variant=''>
                                Remove
                            </Button>
                        </Col> */}
                        </Row>
                        {/* <Row>
                        <Col md={1}>
                            <img src="/google.png" width={50} />
                        </Col>
                        <Col md="4">
                            <Row>
                                <h6>Apple</h6>
                            </Row>
                            <Row>
                                <p>Summer Fang</p>
                            </Row>
                        </Col>
                        <Col>
                            <Button variant=''>
                                Remove
                            </Button>
                        </Col>
                    </Row> */}
                        {/* <Row>
                        <Col md={1}>
                            <Button variant=''>Add</Button>
                        </Col>
                    </Row> */}
                    </Row>
                </Row>
            </>): (
            <Alert color="danger" className="d-none d-lg-block">
                <strong>Please login first</strong>
            </Alert>
            )
            }
        </Container>
        </main>
    );
}

export default Mechant;