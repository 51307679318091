import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { X, Google } from 'react-bootstrap-icons'; // Make sure to install react-bootstrap-icons
import { GoogleLogin } from '@react-oauth/google';

import Container from 'react-bootstrap/Container';

const FloatingDialog = () => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleGoogleLogin = () => {
        // Logic to initiate Google login goes here
        console.log('Google login initiated');
    };


    return (
        <>
            <Container className="px-0" style={{ paddingTop: '100px', paddingBottom: '50px' }}>

                <button onClick={handleShow}>Open Dialog</button>

                <Modal show={show} onHide={handleClose} centered>
                    <Modal.Header>
                        <Modal.Title>Sign up / Register</Modal.Title>
                        <X className="ms-auto" onClick={handleClose} style={{ cursor: 'pointer' }} />
                    </Modal.Header>
                    <Modal.Body>

                        <p>Login with</p>
                        <div>
                            <div>
                                <GoogleLogin
                                    useOneTap
                                    onSuccess={handleGoogleLogin}
                                    onError={() => console.log('Login Failed')}
                                />
                            </div>
                        </div>
                        <hr />
                        <p>By continuing, you agree to our <a href="/optin/sms-terms-of-service.html" target="_blank" rel="noopener noreferrer">Terms of Use</a> and <a href="/optin/privacy-policy.html" target="_blank" rel="noopener noreferrer">Privacy Policy</a>.</p>

                    </Modal.Body>
                </Modal>
            </Container>
        </>
    );
};

export default FloatingDialog;