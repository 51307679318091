import React, { useEffect, useState } from 'react';
import { Alert, Button, Col, Container, Form, Image, Row } from 'react-bootstrap';
import { useProfileContext } from "../home/Layout";
import RestfulAPICall from '../utility/restfulapicall.js';     // Make sure to import the RestfulApiCall utility

const Register = () => {
    const { googleProfile } = useProfileContext();

    const [merchantName, setMerchantName] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [avarta, setAvarta] = useState('');
    const [phones, setPhones] = useState([]);
    const [selectedPhoneId, setSelectedPhoneId] = useState('');


    const handleGoogleLogin = () => {
        // TODO: Implement Google login logic here
        // Use Google API to authenticate the user and retrieve their information
        // Set the retrieved first name, last name, and email in the component state
    };

    const handleSignup = async () => {
        RestfulAPICall(
            `${process.env.REACT_APP_API_TRUEUP}merchants/register`,
            {
                method: 'POST',
                headers: {
                    'accept': 'application/json',
                    'Authorization': `Bearer ${googleProfile.id_token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "merchant_info": {
                      "name": merchantName,
                      "phone": "",
                      "description": "",
                      "address": "",
                      "email": email,
                      "avatar_url": avarta
                    },
                    "user_info": {
                      "first_name": firstName,
                      "last_name": lastName,
                      "provider": "google",
                      "email": email,
                      "phone_number": "string",
                      "sms_disabled": true
                    },
                    "phone_number_id": selectedPhoneId
                  })
            },
            false
        ).then(response => {
            console.log(`Register.js::handleSignup: response = ${JSON.stringify(response)}`);
            window.location.href = '/merchant';

            // if (response.data.success) {
            //     // Redirect the user to the merchant page upon successful sign-up
            //     window.location.href = '/merchant';
            // } else {
            //     // Display an error message to the user if the sign-up failed
            //     console.error('A merchant with the provided name, phone, or email already exists.');
            // }
        }).catch(error => {
            console.error('There was an error!', error);
        });
    };


    useEffect(() => {
        // If the user is not logged in, redirect them to the home page
        if (googleProfile) {
            console.log(`register.js::useEffect: ${googleProfile.id_token}`);
            RestfulAPICall(
                `${process.env.REACT_APP_API_TRUEUP}users/exists`,
                {
                    method: 'POST',
                    headers: {
                        'accept': 'application/json',
                        'Authorization': `Bearer ${googleProfile.id_token}`
                    },
                    body: JSON.stringify('')
                },
                false
            ).then(response => {
                console.log(`Register.js::useEffect: response = ${JSON.stringify(response)}`);
                if (response.data.user_id_exists === true && response.data.merchant_id_exists === true) {
                    // Redirect the user to the merchant page upon successful sign-up
                    window.location.href = '/merchant';
                }
            }).catch(error => {
                console.error('There was an error!', error);
            });


            setFirstName(googleProfile.given_name);
            setLastName(googleProfile.family_name);
            setMerchantName(`${firstName?.trim() || ''} ${lastName?.trim() || ''}`.trim() + "'s Business");

            setEmail(googleProfile.email);
            setAvarta(googleProfile.picture);

            RestfulAPICall(`${process.env.REACT_APP_API_TRUEUP}phone-numbers/available`,
                {
                    method: 'GET',
                    headers: {
                        'accept': 'application/json',
                        'Authorization': `Bearer ${googleProfile.id_token}`,
                    },
                    body: JSON.stringify('')
                },
                false).then(response => {
                    console.log(`register.js::useEffecct:Response =  ${JSON.stringify(response)}`);
                    setPhones(response.data);
                }).catch(error => {
                    console.error('There was an error!', error);
                });
        };


    }, []);


    return (
        <main>
            {googleProfile ? (
                <Container className="px-0" style={{ paddingTop: '100px', paddingBottom: '50px' }}>
                    <Row>
                        <h2>Sign up an merchant account</h2>
                    </Row>

                    <Row>
                        <Row><h6>Merchant Avarta</h6></Row>
                        <Row>
                            <Col md={4}>
                                <Image src={avarta} width={100} rounded />
                            </Col>
                            {/* <Col md={1}>
                    <Form>
                        <Button variant=''>Remove</Button>

                    </Form>
                </Col>
                <Col md={1}>
                    <Form>
                        <Button variant=''>Change</Button>

                    </Form>
                </Col> */}
                        </Row>
                    </Row>
                    <Row>
                        <Row>
                            <h6>Merchant Name *</h6>
                        </Row>
                        <Row className="mb-3">
                            <Col md="5">
                                <Form.Group as={Col} controlId="formMerchant">
                                    {/* <Form.Control>{"TechArtisan LLC"}</Form.Text> */}
                                    <Form.Control placeholder="Enter your business's name"
                                        value={merchantName}
                                        onChange={(e) => setMerchantName(e.target.value)}
                                    />

                                </Form.Group>
                            </Col>
                            {/* <Col>
                    <Button variant=''>
                        Change
                    </Button>
                </Col> */}


                        </Row>
                    </Row>
                    <Row>
                        <Row>
                            <h6>Select an available phone number*</h6>
                            <p>The number is used to send and receive short message (SMS)</p>
                        </Row>
                        <Row className="mb-3">
                            <Col md="5">
                                <Form.Select value={selectedPhoneId} onChange={(event)=>setSelectedPhoneId(event.target.value)} aria-label="Select an available phone number">
                                    {/* <option>Open this select menu</option> */}
                                    {/* <option value="1">+18880506060</option>
                                    <option value="2">+14080506060(Local)</option>
                                    <option value="3">+18000506060</option> */}
                                    {phones.map((item, index) => {
                                        return <option key={index} value={item.id}>{item.phone_number}</option>
                                    })}
                                </Form.Select>
                            </Col>
                            {/* <Col>
                    <Button variant=''>
                        Choose
                    </Button>
                </Col> */}
                        </Row>
                    </Row>
                    <Row>
                        <Row>
                            <h6>Contact</h6>
                        </Row>
                        <Row className="mb-3">
                            <Col md="2">
                                <Form.Group as={Col} controlId="formMerchant">
                                    <Form.Control placeholder='First Name'
                                        value={firstName} onChange={(e) => setFirstName(e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md="2">
                                <Form.Group as={Col} controlId="formMerchant">
                                    <Form.Control placeholder='Last Name'
                                        value={lastName} onChange={(e) => setLastName(e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Row>
                    <Row>
                        <Form>
                            <Button variant='primary' onClick={handleSignup}>Submit</Button>
                        </Form>
                    </Row>
                </Container>
            ) : (
                <Alert>
                    Please sign in to create a merchant account.
                </Alert>
            )}
        </main>
    );
};

export default Register;