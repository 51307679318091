import React, { useState } from "react";
import { Container, Form, Button, Alert } from "react-bootstrap";
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css'

function OptInForm() {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [optInChecked, setOptInChecked] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    // Here, you'd typically send the phone to your backend for processing
    console.log("Phone submitted:", name, phone, optInChecked);
    fetch("https://api.gettrueup.com/optin", {
      method: "POST",
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name: name,
        phone: phone,
        optin_checked: optInChecked,
      }),
    }).then((response) => {
      console.log(response);
      setIsSubmitted(true);
    }).catch((error) => {
      console.error(error);
    });

  };

  return (
    <Container className="px-0" style={{ paddingTop: "100px", paddingBottom: "100px" }}>
      <h1>Opt in SMS</h1>
      <p>Please fill in this form to signup to learn more about TrueUp.</p>
      <hr />
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3" controlId="formBasicName">
          <Form.Label>Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter your name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicName">
          <Form.Label>Phone Number</Form.Label>
          <PhoneInput
            placeholder="Enter phone number"
            value={phone}
            onChange={setPhone}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicCheckbox">
          <Form.Check
            type="checkbox"
            label="I agree to receive SMS messages from TrueUp"
            onChange={(e) => setOptInChecked(e.target.value)}
          />
          <Form.Text className="text-muted">
            By submitting your name and phone number and clicking the checkbox above, you agree to
            our <a href="./sms-terms-of-service.html">Mobile Terms of Service</a> &{" "}
            <a href="./privacy-policy.html">Privacy Policy</a>. You are agreeing to receive periodic
            text messages from TrueUp to inform you about our services. No purchase necessary.
            Message frequency varies. Standard message and data rates may apply. Reply 'STOP' to
            unsubscribe or 'HELP' for more info.
          </Form.Text>
        </Form.Group>
        <Button variant="primary" type="submit">
          Submit
        </Button>
        <Alert show={isSubmitted} variant="success">
          Your information has been submitted successfully.
        </Alert>
      </Form>
    </Container>
  );
}

export default OptInForm;
